import { Directive, HostListener, inject } from '@angular/core';

import { IntercomService } from '@bp/frontend/services/intercom';

@Directive({
	selector: 'button[bpShowRegisterPspSurveyOnClick]',
	standalone: true,
})
export class ShowRegisterPspSurveyDirective {

	private readonly __intercomService = inject(IntercomService);

	@HostListener('click')
	onClick(): void {
		void this.__intercomService.startSurvey('38480668');
	}

}
